import React, { Component } from 'react'
import axios from 'axios';
import Video from 'twilio-video';
import './VideoWindow.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faMicrophone } from '@fortawesome/free-solid-svg-icons'

export default class VideoWindow extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
			roomName: '',
            previewTracks: null,
			localMediaAvailable: false,
			hasJoinedRoom: false,
			hasJoinedRemote: false,
			activeRoom: null, // Track the current active room
			nParticipants: null,
			initCall: false,
			showVideo: true,
			unmuted: true,
			isFullScrean: false,
			showButtons: false
        }
        
		this.handleRoomNameChange = this.handleRoomNameChange.bind(this);
		this.componentDidMount = this.componentDidMount.bind(this);
		this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
		this.setInitCall = this.setInitCall.bind(this);
        this.getMyMedia = this.getMyMedia.bind(this);
        this.roomJoined = this.roomJoined.bind(this);
		this.leaveRoom = this.leaveRoom.bind(this);
        this.detachTracks = this.detachTracks.bind(this);
		this.detachParticipantTracks = this.detachParticipantTracks.bind(this);
		this.toggleVideoShow = this.toggleVideoShow.bind(this);
		this.toggleAudioMute = this.toggleAudioMute.bind(this);
		this.toggleFullScrean = this.toggleFullScrean.bind(this);
		this.isFullScreen = this.isFullScreen.bind(this);
		this.setLeaveRoom = this.setLeaveRoom.bind(this);
	}

	handleRoomNameChange() {
		let referenceCode = window.sessionStorage.getItem('referenceCode');
		this.setState({ roomName: referenceCode });
    }
	
	componentDidMount() {
		if (!this.state.roomName.trim()) {
            this.handleRoomNameChange();
		}
		
		let identity = window.sessionStorage.getItem('identity');
		this.setState({initCall: this.props.initCall, identity})
		axios.get('/token', {params: {identity: identity}}).then(results => {
			const { token } = results.data;
			this.setState({ token });
		});

	}

    fullScreenVideo(video) {
		// are we full-screen?
		if(this.isFullScreen()){
			if (document.exitFullscreen) {
				return document.exitFullscreen();
			}
			if (document.webkitExitFullscreen) {
				return document.webkitExitFullscreen();
			}
			if (document.mozCancelFullScreen) {
				return document.mozCancelFullScreen();
			}
			if (document.msExitFullscreen) {
				return document.msExitFullscreen();
			}
		}
		else {
			if (video.requestFullscreen) {
				return video.requestFullscreen();
			}
			if (video.mozRequestFullScreen) { /* Firefox */
				return video.mozRequestFullScreen();
			}
			if (video.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
				return video.webkitRequestFullscreen();
			}
			if (video.msRequestFullscreen) { /* IE/Edge */
				return video.msRequestFullscreen();
			}
		}
	};

	toggleFullScrean() {
		var majBox = document.getElementsByClassName('major-box')[0];
		this.fullScreenVideo(majBox);
	}

	isFullScreen(){
		this.setState({isFullScrean: !this.state.isFullScrean})
		return !!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement);
	}

	toggleVideoShow() {
		if (this.state.showVideo) {
			this.state.activeRoom.localParticipant.videoTracks.forEach(function(videoTracks, key, map) {
				videoTracks.disable();
			});
		} else {
			this.state.activeRoom.localParticipant.videoTracks.forEach(function(videoTracks, key, map) {
				videoTracks.enable();
			});
		}
		this.setState({ showVideo: !this.state.showVideo })
	}

	toggleAudioMute() {
		if (this.state.unmuted) {
			this.state.activeRoom.localParticipant.audioTracks.forEach(function(audioTrack, key, map) {
				audioTrack.disable();
			});
		} else {
			this.state.activeRoom.localParticipant.audioTracks.forEach(function(audioTrack, key, map) {
				audioTrack.enable();
			});
		}
		this.setState({ unmuted: !this.state.unmuted })
	}

	setInitCall(initCall) {
		if (!initCall && this.state.activeRoom !== null) {
			this.setState({showButtons: false})
			this.leaveRoom();
		}

		if (initCall) {
			this.getMyMedia();
			
		}
	}

	componentWillReceiveProps(newProps) {
		if (this.props.initCall !== newProps.initCall) {
				this.setState({initCall: newProps.initCall});
				this.setInitCall(newProps.initCall);
		}
	}
    
    //Get local user media
    getMyMedia() {
		console.log("Joining room '" + this.state.roomName + "'...");
		let connectOptions = {
			name: this.state.roomName
		};

		if (this.state.previewTracks) {
			connectOptions.tracks = this.state.previewTracks;
		}
		
		// Join the Room with the token from the server and the
        // LocalParticipant's Tracks.
		Video.connect(this.state.token, connectOptions).then(this.roomJoined, error => {
			alert('Could not connect to Twilio: ' + error.message);
		});
    }

    attachTracks(tracks, container) {
		tracks.forEach(track => {
			container.appendChild(track.attach());
		});
	}

	// Attaches a track to a specified DOM container
	attachParticipantTracks(participant, container) {
		var tracks = Array.from(participant.tracks.values());
		this.attachTracks(tracks, container);
	}

	detachTracks(tracks) {
        this.setState({hasJoinedRemote: false});
		tracks.forEach(track => {
			track.detach().forEach(detachedElement => {
				detachedElement.remove();
			});
		});
	}

	detachParticipantTracks(participant) {
		var tracks = Array.from(participant.tracks.values());
		this.detachTracks(tracks);
    }
    
    roomJoined(room) {
		// Called when a participant joins a room
		console.log("Joined as '" + this.state.identity + "'");
		this.setState({
			activeRoom: room,
			localMediaAvailable: true,
			hasJoinedRoom: true
		});

        // Attach LocalParticipant's Tracks, if not already attached.        
		var previewContainer = this.refs.localMedia;
		if (!previewContainer.querySelector('video')) {
			this.attachParticipantTracks(room.localParticipant, previewContainer);
		}

		// Attach the Tracks of the Room's Participants.
		room.participants.forEach(participant => {
			console.log("Already in Room: '" + participant.identity + "'");
			var previewContainer = this.refs.remoteMedia;
			this.attachParticipantTracks(participant, previewContainer);
		});

		// When a Participant joins the Room, log the event.
		room.on('participantConnected', participant => {
			console.log("Joining: '" + participant.identity + "'");
		});

		// When a Participant adds a Track, attach it to the DOM.
		room.on('trackAdded', (track, participant) => {
			this.setState({hasJoinedRemote: true});
			console.log(participant.identity + ' added track: ' + track.kind);
			var previewContainer = this.refs.remoteMedia;
			this.attachTracks([track], previewContainer);
		});

		// When a Participant removes a Track, detach it from the DOM.
		room.on('trackRemoved', (track, participant) => {
			console.log(participant.identity + ' removed track: ' + track.kind);
			this.detachTracks([track]);
		});

		// When a Participant leaves the Room, detach its Tracks.
		room.on('participantDisconnected', participant => {
			console.log("Participant '" + participant.identity + "' left the room");
			this.detachParticipantTracks(participant);
		});

		// Once the LocalParticipant leaves the room, detach the Tracks
		// of all Participants, including that of the LocalParticipant.
		room.on('disconnected', () => {
			if (this.state.previewTracks) {
				this.state.previewTracks.forEach(track => {
					track.stop();
				});
			}
			this.detachParticipantTracks(room.localParticipant);
			room.participants.forEach(this.detachParticipantTracks);
			this.setState({ activeRoom: null });
			this.setState({ hasJoinedRoom: false, localMediaAvailable: false });
		});
		this.setState({nParticipants: Array.from(room.participants)})
		this.setState({showButtons: true})
	}
	
	leaveRoom() {
		if (this.isFullScreen()) {
			if (document.exitFullscreen) {
				return document.exitFullscreen();
			}
			if (document.webkitExitFullscreen) {
				return document.webkitExitFullscreen();
			}
			if (document.mozCancelFullScreen) {
				return document.mozCancelFullScreen();
			}
			if (document.msExitFullscreen) {
				return document.msExitFullscreen();
			}
		}
		this.state.activeRoom.disconnect();
		this.setState({ hasJoinedRoom: false, localMediaAvailable: false });
	}

	setLeaveRoom() {
		this.props.setLeaveRoom();
	}

    render() {
        // Only show video track after user has joined a room
		let showLocalTrack = this.state.localMediaAvailable ? (
            <div ref="localMedia" className="local-cam"/>
		) : (
			''
        );
        
        return (
            // <!-- Video to Video Window -->
            <section className={"major-box fb-col-item " + (this.state.initCall ? '' : 'hide-vid')} >
				<div className={this.state.initCall ? 'hide' : 'img-welcome'}></div>
                {/* <!-- Remote User Video --> */}

                <div ref="remoteMedia" className="remote-cam"/>

                <section className={"minor-box " + (this.state.initCall ? '' : 'box-standby')}>
                    {showLocalTrack}
                </section>

				{/* <!-- Remote Username Label and Hangup Button --> */}
                <div id="remote-username" 
                     className={(this.state.showButtons ? ' btns-visibility ' : '')+(this.state.isFullScrean ? ' btns-full-screan ' : '')}>
					<div className="buttons-action">
						<ul>
							<li id="camera" className={(this.state.showVideo ? '' : 'button-deactivated')} onClick={this.toggleVideoShow}>
								<FontAwesomeIcon icon={faVideo} />
							</li>
							<li id="microphone" className={(this.state.unmuted ? '' : 'button-deactivated')} onClick={this.toggleAudioMute}>
								<FontAwesomeIcon icon={faMicrophone} />
							</li>
                            <li id="hang-up" onClick={this.setLeaveRoom}>

                            </li>
						</ul>
					</div>
				</div>
                {/* <!-- Expand and Chat buttons for P2P chat --> */}
                <div id="video-menu" className={(this.state.showButtons ? 'btns-visibility' : '')}>
                    <div id="remote-full-screen" className="rounded-btn expand remote-fs-unselected" onClick={this.toggleFullScrean}></div>
                </div>
                {/* <!-- Company Logo --> */}
            </section>    
        )
    }
}
