import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from "classnames"
import './Chat.css';

export default class Message extends Component {
    static propTypes = {
        author: PropTypes.string,
        body: PropTypes.string.isRequired,
        me: PropTypes.bool,
    }
    
    render() {
        const classes = classNames('Message', {
            'msg-info': !this.props.author,
            'msg-user': this.props.me,
            'msg-peer': !this.props.author && this.props.me
        })

        const time = new Date().getHours() + ':' + new Date().getMinutes();

        return (
            <div className={"chat-msg " + (classes)}>
                {this.props.author && (
                    <div className="msg-header">{this.props.author}:
                        <div className="msg-time">{time}</div>
                    </div>
                )}
                {this.props.body}
            </div>
        )
    }
}
