import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Message from './Message';
import './Chat.css';

export default class MessageList extends Component {
    static propTypes = {
        messages: PropTypes.arrayOf(PropTypes.object)
    }
        
    static defaultProps = {
        messages: [],
    }
    
    componentDidUpdate = () => {
        var container = document.getElementById("messages");
        container.scrollTop = container.scrollHeight;
    }
    
    render() {
        return (
            <section className="MessageList horizontal-bar fb-col-item" ref={(node) => (this.node = node)}>
                <div id="messages">
                {this.props.messages.map((message, i) => (
                    <Message key={i} {...message} />
                ))}
                </div>
            </section>
        )
    }
}
