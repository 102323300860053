import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Chat.css';

export default class MessageForm extends Component {
    static propTypes = {
        onMessageSend: PropTypes.func.isRequired,
    }

    handleFormSubmit = (event) => {
        event.preventDefault()
        this.props.onMessageSend(this.input.value)
        this.input.value = ""
    }

    render() {
        return (
            <form id="sendMessage" onSubmit={this.handleFormSubmit}>
                <input
                    type="text"
                    id="message"
                    autoComplete="off"
                    ref={(node) => (this.input = node)}
                    placeholder="Escreva aqui"
                />
                <button id="submit-btn" className="chat-hidden" type="submit">
                    
                </button>
            </form>
        )
    }
}
