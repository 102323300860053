import React, { Component } from 'react';
import classNames from 'classnames/bind';
import io from 'socket.io-client';
import axios from 'axios';
import './VerticalBar.css';
import Modal from '../modals/Modal/Modal';
import Chat from './ChatContainer/Chat';

export default class VerticaBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalList: false,
            modalCalling: false,
            hideVerticalBar: false,
            objParticipant: {},
            arrayParticipants: [],
            userClicked: '',
            initCall: false,
            sendSocket: false,
        };

        this.toggleModalList = this.toggleModalList.bind(this);
        this.toggleModalCalling = this.toggleModalCalling.bind(this);
        this.getParticipants = this.getParticipants.bind(this);
        this.toggleVerticalBar = this.toggleVerticalBar.bind(this);
        this.callParticipant = this.callParticipant.bind(this);
        this.setSocket = this.setSocket.bind(this);
        this.getArrayParticipants = this.getArrayParticipants.bind(this);
        this.atenderChamada = this.atenderChamada.bind(this);
        this.recusarChamada = this.recusarChamada.bind(this);
        this.setInitCall = this.setInitCall.bind(this);
        this.leaveRoom = this.leaveRoom.bind(this);
        this.selectPartcipant = this.selectPartcipant.bind(this);
        this.getListFiles = this.getListFiles.bind(this);
        this.getListParticipants = this.getListParticipants.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    }

    componentWillReceiveProps(newProps) {
		if (this.props.setLeaveRoom !== newProps.setLeaveRoom) {
				this.leaveRoom(newProps.setLeaveRoom);
		}
	}

    async setSocket() {
        let socket = io('https://tutoronline.influx.com.br', {
            query: { 
                user: JSON.stringify(this.state.objParticipant)
            }
        });

        this.setState({socket, sendSocket: true})

        await this.getListParticipants();

        await socket.on('ligando', user => {
            if (user.userJson.token === this.state.objParticipant.token) {
                this.setState({message: `Chamando ${user.userTarget.identity}, aguarde ele responder!`})
            }
            for (let i = 0; i < this.state.arrayParticipants.length; i++) {
                if (user.userJson.token === this.state.arrayParticipants[i].token) {
                    this.setState({participantCalling: this.state.arrayParticipants[i]});
                    this.toggleModalCalling();
                }

            }
        });

        await socket.on('inicia chamada', user => {
            if (user.user.token !== this.state.objParticipant.token) {
                this.setState({message: `${user.user.identity} aceitou a sua chamada!`})
            }
            this.setState({message: `Aguarde a conexão de vídeo com o outro participante...`})
            this.setState({initCall: true});
            this.setInitCall();
        });

        await socket.on('desligar call', user => {
            if (this.state.initCall) {
                this.setState({message: `${user.user.identity} desligou a sua chamada!`})
            }
            this.setState({initCall: false});
            this.setInitCall();
        });

        await socket.on('chamada recusada', user => {
            if (user.user.token !== this.state.objParticipant.token) {
                this.setState({message: `${user.user.identity} rejeitou a sua chamada!`})
            }
            this.setState({initCall: false});
            this.setInitCall();
        });
    }

    getListParticipants() {
        this.state.socket.on('get list participants', users => {
            let totalParticipants = users.users;
            let arrayParticipants = this.getArrayParticipants(totalParticipants);
            this.setState({ arrayParticipants })
        });
    }
    
    setInitCall() {
        this.props.initCall(this.state.initCall);
    }

    atenderChamada() {
        this.toggleModalCalling();
        this.state.socket.emit('atendeu');
    }

    recusarChamada() {
        this.toggleModalCalling();
        this.state.socket.emit('recusou');
    }

    getArrayParticipants(totalParticipants) {
        const objParticipant = this.state.objParticipant
        return totalParticipants.filter(function(obj) {
            return obj.token !== objParticipant.token;
        })
    }

    getParticipants(childData) {
        this.setState({objParticipant: childData})
        this.setSocket();
    }

    toggleModalList() {
        this.getListFiles();
    }

    getListFiles() {
        let token = window.sessionStorage.getItem('token')
        let referenceCode = window.sessionStorage.getItem('referenceCode')
        this.setState({modalList: !this.state.modalList})
        axios.get('https://api.portal.influx.morphy.com.br/file/tutor-class/' + referenceCode, 
            { headers: { Authorization: 'Bearer ' + token } })
            .then(res => {
                var listFiles = res.data.data
                this.setState({listFiles})
            })
    };

    toggleModalCalling() {
        this.setState({modalCalling: !this.state.modalCalling})
    }

    toggleVerticalBar() {
        this.setState({hideVerticalBar: !this.state.hideVerticalBar})
    }

    selectPartcipant(selectPartcipant) {
        this.setState({selectPartcipant})
    }

    async callParticipant() {
        if (!this.state.selectPartcipant) { 
            alert('selecione algum participante'); 
            return; 
        }
        await this.state.socket.emit('ligou', this.state.selectPartcipant);
    }

    async leaveRoom(setLeaveRoom) {
        if (setLeaveRoom) {
            await this.state.socket.emit('desligou');
        }
    }
    
    logOut() {
        window.top.location.href = 'https://portal.influx.com.br';
    }

    render() {
        const callingModal = this.state.modalCalling ? (
            <Modal>
                <div className="jquery-modal blocker current">
                    <div className="modal modal-calling">
                        <p>
                            {this.state.participantCalling.identity} está te ligando. Deseja atender?
                        </p>
                        <div className="btns-calling">
                            <button className="btn-refuse" onClick={this.recusarChamada}>Recusar</button>
                            <button className="btn-accept" onClick={this.atenderChamada}>Aceitar</button>
                        </div>
                        {/* eslint-disable-next-line */}
                        <a onClick={this.toggleModalCalling} className="close-modal">Close</a>
                    </div>
                </div>
            </Modal>
        ) : null;
        const listFilesModal = this.state.modalList ? (
            <Modal>
                <div className="jquery-modal blocker current">
                    <div className="modal">
                        <p>
                            <strong>Lista de Arquivos:</strong>
                        </p>
                        { this.state.listFiles ? 
                            <ul id="documents">
                                {this.state.listFiles.length === 0 ? (
                                    <li>
                                        <p>Não há arquivos disponíveis.</p>
                                    </li>
                                ) : (
                                    this.state.listFiles.map((value, index) => {
                                        return (
                                            <li>
                                                <a target="blank" href={value.file} download={value.file}>{value.name}</a>
                                            </li>
                                        )
                                    })
                                )}
                            </ul> : 
                            <p>loading...</p>
                        }

                        {/* eslint-disable-next-line */}
                        <a onClick={this.toggleModalList} className="close-modal">Close</a>
                    </div>
                </div>
            </Modal>
        ) : null;
        
        return (
            <section className={"vertical-bar"+(this.state.hideVerticalBar?'hide-vartical-bar':'')}>
                {/* <!-- User Management UI --> */}
                <div id="userInfo" className={this.state.hideVerticalBar?'hide':''}>
                    <h1 className="title-text" id="username-label">{this.state.objParticipant.identity}</h1>

                    <div className="box">
                        <div id="log-out" className="menu-icon-btns sign-out" onClick={this.logOut}>
                            <span>Sair</span>
                        </div>
                        <div id="call-peer" className={"menu-icon-btns start-call"+(this.state.initCall ? ' active ' : '' )} onClick={this.callParticipant}>
                            <span>Iniciar Aula</span>
                        </div>
                        <div id="call-peer" className="menu-icon-btns files" onClick={this.toggleModalList}>
                            <span>Arquivos</span>
                        </div>
                    </div>
                    
                </div>

                <div id="peers" className={this.state.hideVerticalBar?'hide':''}>
                    {/* <!-- Peers List --> */}
                    {this.state.arrayParticipants.map((value, index) => {
                        var peerClasses = '';
                        if (this.state.selectPartcipant) {
                            peerClasses = classNames({
                                'peer': true,
                                'selected': this.state.selectPartcipant.token === value.token
                            });
                        } else {
                            peerClasses = classNames({
                                'peer': true
                            });
                        }
                        return (
                            <div className={peerClasses} 
                                onClick={() => this.selectPartcipant(value)}>
                                <div className="peer-icon user-icon-img"></div>
                                <span className="peer-label" value="value.token" key={index}>{value.identity}</span>
                            </div>
                        )
                    })}
                </div>
                {this.state.hideVerticalBar ? (
                    <div onClick={this.toggleVerticalBar} id="showColumn" className="hide-btn"></div>
                ) : (
                    <div onClick={this.toggleVerticalBar} id="hideColumn" className="hide-btn"></div>
                )}

                <div className={this.state.hideVerticalBar?'hide':''}>
                    <Chat message={this.state.message} parentCallback={this.getParticipants}></Chat>
                </div>

                {callingModal}
                {listFilesModal}
            </section>
        );
    }
}