import React, { Component } from 'react';

import VerticalBar from './VerticalBar/VerticalBar';
import VideoWindow from './RightPane/VideoWindow/VideoWindow';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          initCall: false,
          setLeaveRoom: false
        };

        this.isInitCall = this.isInitCall.bind(this);
        this.setLeaveRoom = this.setLeaveRoom.bind(this);
        
        this.getUrl();
    }

    getUrl() {
      var url = window.location.href;
      url = url.split('&');
  
      var identity = url[0].split('=')[1];
      var referenceCode = url[1].split('=')[1];
      var token = url[2].split('=')[1];

      window.sessionStorage.setItem('identity', identity);
      window.sessionStorage.setItem('referenceCode', referenceCode);
      window.sessionStorage.setItem('token', token);
    }

    isInitCall(initCall) {
      this.setState({initCall: initCall, setLeaveRoom: false})
    }

    setLeaveRoom() {
      this.setState({setLeaveRoom: true})
    }

    render() {
      return (
        <div id="xsdk-video-call">
          <VerticalBar setLeaveRoom={this.state.setLeaveRoom} initCall={this.isInitCall}></VerticalBar>
          <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme)}>
            <div className="full-screan">
                <VideoWindow setLeaveRoom={this.setLeaveRoom} initCall={this.state.initCall}></VideoWindow>
            </div>
          </MuiThemeProvider>
        </div>
      );
    }
}
