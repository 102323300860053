import React, { Component } from 'react'
import $ from 'jquery';
import TwilioChat from 'twilio-chat';
import Modal from '../../modals/Modal/Modal';
import MessageList from './MessageList';
import MessageForm from './MessageForm';
import './Chat.css';

export default class Chat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            messages: [],
            username: null,
            channel: null,
            user: {},
            modalLimiUser: false
        }

        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.joinGeneralChannel = this.joinGeneralChannel.bind(this);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.message !== this.props.message) {
            this.addMessage({body: newProps.message})
        }
    }
    
    componentDidMount() {
        this.getToken()
        .then(this.createChatClient)
        .then(this.joinGeneralChannel)
        .then(this.configureChannelEvents)
        .catch((error) => {
            this.addMessage({ body: `Error: ${error.message}` })
        })
        
    }
    
    getToken = () => {
        return new Promise((resolve, reject) => {
            this.addMessage({ body: 'Conectando na sala...' })
        
            var identity = window.sessionStorage.getItem('identity');
            $.getJSON('/token', {identity: identity}, (user) => {
                this.setState({ username: user.identity, user: user })
                resolve(user)
            }).fail(() => {
                reject(Error('Failed to connect.'))
            })
        })
    }
    
    createChatClient = (token) => {
        return new Promise((resolve, reject) => {
            resolve(new TwilioChat(token.token))
        })
    }

    logOut() {
        window.top.location.href = 'https://portal.influx.com.br';
    }

    getErrorLimit(e) {
        if (e.code === 50403) {
            this.setState({modalLimiUser: true})
        }
    }
    
    joinGeneralChannel = (chatClient) => {
        return new Promise((resolve, reject) => {
            chatClient.getSubscribedChannels().then(() => {
                chatClient.getChannelByUniqueName('general').then((channel) => {
                    this.setState({ channel })
            
                    channel.join().then(() => {
                        this.addMessage({ body: `Bem Vindo(a)!` })
                        this.props.parentCallback(this.state.user);
                        window.addEventListener('beforeunload', () => channel.leave())
                    }).catch(e => this.getErrorLimit(e))

                    resolve(channel)
                }).catch(() => this.createGeneralChannel(chatClient))
            }).catch(() => reject(Error('Could not get channel list.')))
        })
    }
    
    createGeneralChannel = (chatClient) => {
        return new Promise((resolve, reject) => {
            chatClient
            .createChannel({ uniqueName: 'general', friendlyName: 'General Chat' })
            .then(() => this.joinGeneralChannel(chatClient))
            .catch(() => reject(Error('Could not create general channel.')))
        })
    }
    
    addMessage = (message) => {
        const messageData = { ...message, me: message.author === this.state.username }
        this.setState({
            messages: [...this.state.messages, messageData],
        })
    }
    
    handleNewMessage = (text) => {
        if (this.state.channel) {
            this.state.channel.sendMessage(text)
        }
    }
    
    configureChannelEvents = (channel) => {
        channel.on('messageAdded', ({ author, body }) => {
            this.addMessage({ author, body })
        })
    
        channel.on('memberJoined', (member) => {
            this.addMessage({ body: `${member.identity} entrou na sala.` })
        })
    
        channel.on('memberLeft', (member) => {
            this.addMessage({ body: `${member.identity} saiu da sala.` })
        })
    }

    render() {
        const limiUserModal = this.state.modalLimiUser ? (
            <Modal>
                <div className="jquery-modal blocker current">
                    <div className="modal">
                        <p>
                            Apenas é permitida uma conversa de um-por-um, verifique se já tem 2 usuários na conversa!
                        </p>
                        <div className="btns-calling">
                            <button className="btn-accept" onClick={() => {window.location.reload()}}>Recarregar a página</button>
                            <button className="btn-refuse" onClick={this.logOut}>Sair</button>
                        </div>
                    </div>
                </div>
            </Modal>
        ) : null;
        return (
            <div className="chat-content">

                {/* <!-- CHAT --> */}
                <MessageList messages={this.state.messages} />

                {/* <!-- Sends user messages --> */}
                <MessageForm onMessageSend={this.handleNewMessage} />
                
                {limiUserModal}
            </div>
        )
    }
}
